<template>
  <div class="container-fluid p-0 nav-bar">
    <nav class="navbar navbar-expand-lg bg-none navbar-dark py-3">
      <a class="navbar-brand px-lg-4 m-0">
        <router-link to="/">
          <img class="logo" src="../assets/img/logosimple.png" alt="digital codex">
        </router-link>
      </a>
      <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
        <div class="navbar-nav ml-auto p-4">
          <router-link to="/" class="nav-item nav-link" v-bind:class="{ active: isActive('/')}">Inicio</router-link>
          <router-link to="/about" class="nav-item nav-link" v-bind:class="{ active: isActive('/about')}">Quienes Somos</router-link>
          <router-link to="/service" class="nav-item nav-link" v-bind:class="{ active: isActive('/service')}">Servicios</router-link>
          <router-link to="/contact" class="nav-item nav-link" v-bind:class="{ active: isActive('/contact')}">Contacto</router-link>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: "NavbarMenu",
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  }
}
</script>
<style>
.logo {
  width: 300px;
  height: 200px;
}

@media (max-width: 991.98px) {
  .logo {
    width: 200px;
    height: 100px;
  }
}
</style>
