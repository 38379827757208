<script>
import TitleComponent from "@/components/TitleComponent.vue";

export default {
  name: "ContactComponent",
  components: {TitleComponent}
}
</script>

<template>
 <TitleComponent title="Contacto"/>
  <div class="container-fluid pt-5">
    <div class="container">
      <div class="section-title">
        <h4 class="text-primary text-uppercase" style="letter-spacing: 5px;">Contactanos</h4>
        <h1 class="display-4">Siéntete libre de contactarnos</h1>
      </div>
      <div class="row px-3 pb-2">
        <div class="col-sm-4 text-center mb-3">
          <i class="fa fa-2x fa-map-marker-alt mb-3 text-primary"></i>
          <h4 class="font-weight-bold">Direccion</h4>
          <p>Cra 12 # 1177, Tunja, Boyacá, Colombia</p>
        </div>
        <div class="col-sm-4 text-center mb-3">
          <i class="fa fa-2x fa-phone-alt mb-3 text-primary"></i>
          <h4 class="font-weight-bold">Celular</h4>
          <p>+57 322 610 47 89</p>
        </div>
        <div class="col-sm-4 text-center mb-3">
          <i class="far fa-2x fa-envelope mb-3 text-primary"></i>
          <h4 class="font-weight-bold">Email</h4>
          <p>contacto@digitalcodex.com.co</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 pb-5">
          <iframe style="width: 100%; height: 443px;"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d619.3258372657154!2d-73.3668467661402!3d5.525506859812861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e6a7dd6ebcb2915%3A0x44bf93a959399707!2sCra.%2012%20%231159%2C%20Tunja%2C%20Boyac%C3%A1!5e0!3m2!1ses-419!2sco!4v1708449900434!5m2!1ses-419!2sco"
                  frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
        <div class="col-md-6 pb-5">
          <div class="contact-form">
            <div id="success"></div>
            <form name="sentMessage" id="contactForm" novalidate="novalidate">
              <div class="control-group">
                <input type="text" class="form-control bg-transparent p-4" id="name" placeholder="Nombre"
                       required="required" data-validation-required-message="Please enter your name" />
                <p class="help-block text-danger"></p>
              </div>
              <div class="control-group">
                <input type="email" class="form-control bg-transparent p-4" id="email" placeholder="Email"
                       required="required" data-validation-required-message="Please enter your email" />
                <p class="help-block text-danger"></p>
              </div>
              <div class="control-group">
                <input type="text" class="form-control bg-transparent p-4" id="subject" placeholder="Asunto"
                       required="required" data-validation-required-message="Please enter a subject" />
                <p class="help-block text-danger"></p>
              </div>
              <div class="control-group">
                                <textarea class="form-control bg-transparent py-3 px-4" rows="5" id="message" placeholder="Mensaje"
                                          required="required"
                                          data-validation-required-message="Please enter your message"></textarea>
                <p class="help-block text-danger"></p>
              </div>
              <div>
                <button class="btn btn-primary font-weight-bold py-3 px-5" type="submit" id="sendMessageButton">Enviar Mensaje</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
