<template>
  <div class="container-fluid p-0 mb-5">
    <div id="blog-carousel" class="carousel slide overlay-bottom" data-ride="carousel">
      <div class="carousel-inner">
        <Carousel :autoplay=8000 :wrap-around="true">
          <Slide :key="1">
            <div class="carousel__item">
              <img class="w-100" src="../assets/img/bg-1.jpg" alt="Desarrollo web">
              <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <h2 class="text-primary font-weight-medium m-0">Unete a la</h2>
                <h1 class="display-1 text-white m-0">Revolución Digital</h1>
                <h2 class="text-white m-0"></h2>
              </div>
            </div>
          </Slide>
          <Slide :key="2">
            <div class="carousel__item">
              <img class="w-100" src="../assets/img/bg-2.jpg" alt="Nuevas tecnologias">
              <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <h2 class="text-primary font-weight-medium m-0">Te conectamos con</h2>
                <h1 class="display-1 text-white m-0">Nuevas Tecnologias</h1>
                <h2 class="text-white m-0"></h2>
              </div>
            </div>
          </Slide>
          <Slide :key="3">
            <div class="carousel__item">
              <img class="w-100" src="../assets/img/bg-5.jpg" alt="Data y analitica">
              <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <h2 class="text-primary font-weight-medium m-0">Potenciamos tu empresa con</h2>
                <h1 class="display-1 text-white m-0">Data & Analytics</h1>
                <h2 class="text-white m-0"></h2>
              </div>
            </div>
          </Slide>
          <template #addons>
            <Navigation>
              <template #next>
                <font-awesome-icon class="chevron-right" icon="chevron-right" />
              </template>
              <template #prev>
                <font-awesome-icon class="chevron-left" icon="chevron-left" />
              </template>
            </Navigation>
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>
<script>
import {Carousel, Navigation, Slide} from 'vue3-carousel'
export default {
  name:"CarouselSlider",
  components: {
    Carousel,
    Slide,
    Navigation
  },
}
</script>
<style>
.chevron-left{
  color: #ffffff;
  opacity: 0.5;
  margin-left: 200px;
}
.chevron-right{
  color: #ffffff;
  opacity: 0.5;
  margin-right: 200px;
}
</style>
