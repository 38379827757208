<script>
export default {
  name: "HowComponent",
  data() {
    return {
      menuItems: [
        { id: 1, name: 'Comprender tus necesidades', description: 'Primero, nos reunimos contigo para entender tus necesidades y objetivos comerciales. Nos sumergimos en tu visión para comprender tus requisitos específicos y garantizar que estemos alineados con tus metas.', image: require('../assets/img/necesidades.jpeg') },
        { id: 2, name: 'Diseño y Planificación', description: 'Una vez que comprendemos completamente tus necesidades, nuestro equipo de expertos trabaja en el diseño detallado y la planificación del proyecto. Definimos los hitos, entregables y cronograma para garantizar una ejecución fluida.', image: require('../assets/img/diseño.jpeg') },
        { id: 3, name: 'Desarrollo y Construcción', description: 'Aquí es donde nuestra creatividad y experiencia técnica entran en juego. Utilizando las últimas tecnologías y mejores prácticas de desarrollo, construimos tu software, sitio web o aplicación móvil con un enfoque en la calidad y la escalabilidad.', image: require('../assets/img/desarrollo.jpeg') },
        { id: 4, name: 'Pruebas y Control de Calidad', description: 'No lanzamos nada al mercado sin una rigurosa fase de pruebas y control de calidad. Realizamos pruebas exhaustivas para garantizar que tu producto funcione de manera óptima en todos los escenarios.', image: require('../assets/img/calidad.jpeg') },
        { id: 5, name: 'Implementación y Entrega', description: 'Una vez completadas las pruebas, implementamos la solución y la entregamos a tiempo y dentro del presupuesto acordado. Nos aseguramos de proporcionar el soporte necesario durante la fase de implementación y estamos disponibles para cualquier consulta posterior a la entrega.', image: require('../assets/img/entrega.jpeg') },
        { id: 6, name: 'Mantenimiento y Actualizaciones', description: 'Nuestro compromiso no termina con la entrega. Ofrecemos servicios de mantenimiento continuo para garantizar que tu software esté siempre actualizado y funcionando sin problemas. También estamos disponibles para implementar nuevas características y mejoras según tus necesidades cambiantes.', image: require('../assets/img/mantenimiento.jpeg') },
        { id: 7, name: 'Soporte al Cliente', description: 'Nuestro equipo de soporte está siempre disponible para responder a tus preguntas, resolver problemas y proporcionar asistencia técnica cuando la necesites. Tu satisfacción es nuestra máxima prioridad.', image: require('../assets/img/soporte2.jpeg') }
      ]
    };
  }
}
</script>
<template>
  <div class="container-fluid pt-5">
    <div class="container">
      <div class="section-title">
        <h4 class="text-primary text-uppercase" style="letter-spacing: 5px;">¿Cómo lo hacemos?</h4>
        <h1 class="display-4">"Trazando el camino hacia el futuro"</h1>
      </div>
      <div class="row">
        <div class="col-lg-6" v-for="item in menuItems" :key="item.id">
          <div class="row text-left mb-5">
            <div class="col-4 col-sm-3">
              <img class="w-100 rounded-circle mb-3 mb-sm-0" :src="item.image" alt="Desarrollo software">
            </div>
            <div class="col-8 col-sm-9">
              <h4 class="text-primary font-weight-bold">{{ item.name }}</h4>
              <p class="m-0">{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
</style>
