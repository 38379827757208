<script setup>

</script>

<template>
  <div class="container-fluid footer text-white mt-5 pt-5 px-0 position-relative overlay-top">
    <div class="row mx-0 pt-5 px-sm-3 px-lg-5 mt-4">
      <div class="col-lg-3 col-md-6 mb-5">
        <h4 class="text-white text-uppercase mb-4" style="letter-spacing: 3px;">Ponte en contacto</h4>
        <p><i class="fa fa-phone-alt mr-2"></i>+57 322 610 4789</p>
        <p class="m-0"><i class="fa fa-envelope mr-2"></i>contacto@digitalcodex.com.co</p>
      </div>
      <div class="col-lg-3 col-md-6 mb-5">
        <h4 class="text-white text-uppercase mb-4" style="letter-spacing: 3px;">Síguenos</h4>
        <p>Síguenos en nuestras redes sociales y mantente al día con todas nuestras novedades y contenido exclusivo</p>
        <div class="align-items-center">
          <a class="btn btn-lg btn-outline-light btn-lg-square mr-2" href="#"><font-awesome-icon icon="fa-brands fa-facebook"/></a>
          <a class="btn btn-lg btn-outline-light btn-lg-square mr-2" href="#"><font-awesome-icon icon="fa-brands fa-twitter"/></a>
          <a class="btn btn-lg btn-outline-light btn-lg-square mr-2" href="#"><font-awesome-icon icon="fa-brands fa-linkedin"/></a>
          <a class="btn btn-lg btn-outline-light btn-lg-square mr-2" href="#"><font-awesome-icon icon="fa-brands fa-instagram"/></a>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mb-5">
        <h4 class="text-white text-uppercase mb-4" style="letter-spacing: 3px;">Horario Atención</h4>
        <div>
          <h6 class="text-white text-uppercase">Lunes - Viernes</h6>
          <p>8.00 AM - 8.00 PM</p>
          <h6 class="text-white text-uppercase">Sábado</h6>
          <p>9.00 AM - 2.00 PM</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mb-5">
        <h4 class="text-white text-uppercase mb-4" style="letter-spacing: 3px;">Suscribete</h4>
        <p class="text-justify">Únete a nuestra comunidad hoy mismo y mantente al tanto de todas las últimas noticias, actualizaciones y ofertas especiales</p>
        <div class="w-100">
          <div class="input-group">
            <input type="text" class="form-control border-light" style="padding: 25px;" placeholder="Tú Email">
            <div class="input-group-append">
              <button class="btn btn-primary font-weight-bold px-3">Suscribir</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid text-center text-white border-top mt-4 py-4 px-sm-3 px-md-5" style="border-color: rgba(256, 256, 256, .1) !important;">
      <p class="mb-2 text-white">Copyright &copy; <a class="font-weight-bold" href="#">Digital Codex S.A.S</a>. All Rights Reserved.</p>
      <p class="m-0 text-white">Designed by <a class="font-weight-bold" href="#">Digital Codex</a></p>
    </div>
  </div>
</template>

<style scoped>

</style>
