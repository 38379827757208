<script>
export default {
  name: "TitleComponent",
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<template>
  <div class="container-fluid page-header mb-5 position-relative overlay-bottom">
    <div class="d-flex flex-column align-items-center justify-content-center pt-0 pt-lg-5" style="min-height: 400px">
      <h1 class="display-4 mb-3 mt-0 mt-lg-5 text-white text-uppercase">{{ title }}</h1>
    </div>
  </div>
</template>

<style scoped>

</style>
