<template>
  <div class="offer container-fluid my-5 py-5 text-center position-relative overlay-top overlay-bottom">
    <div class="container py-5">
      <h1 class="display-3 text-primary mt-3">Conoce nuestras técnologias</h1>
      <Carousel :autoplay=2000 :items-to-show="5" :wrap-around="true">
        <Slide v-for="(icon, index) in icons" :key="index">
          <div class="m-5 ml-md-4 carousel__item">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h1 class="display-3 text-white m-0">
                <font-awesome-icon :icon="icon"/>
              </h1>
            </div>
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>


<script>
import {Carousel, Slide} from 'vue3-carousel'

export default {
  name: "FreeSection",
  components: {
    Carousel, Slide
  },
  data() {
    return {
      icons: [
        "fa-brands fa-java",
        "fa-brands fa-react",
        "fa-brands fa-python",
        "fa-brands fa-linux",
        "fa-brands fa-node",
        "fa-brands fa-wordpress",
        "fa-brands fa-github",
        "fa-brands fa-figma",
        "fa-brands fa-docker",
        "fa-brands fa-symfony",
        "fa-brands fa-js",
        "fa-brands fa-git",
        "fa-brands fa-android",
        "fa-solid fa-database",
        "fa-brands fa-angular",
        "fa-brands fa-vuejs",
      ]
    };
  }
}
</script>
