<template>
  <NavbarMenu/>
  <TitleComponent title="Servicios"/>
  <ServiceComponent/>
  <FooterComponent/>
</template>

<script>
import ServiceComponent from "@/components/ServiceComponent.vue";
import NavbarMenu from "@/components/NavbarMenu.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import TitleComponent from "@/components/TitleComponent.vue";

export default {
  name: "ServiceView",
  components: {TitleComponent, FooterComponent, NavbarMenu, ServiceComponent}
}
</script>
