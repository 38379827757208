<template>
  <NavbarMenu/>
  <ContactComponent/>
  <FooterComponent/>
</template>

<script>
import ContactComponent from "@/components/ContactComponent.vue";
import NavbarMenu from "@/components/NavbarMenu.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "ContactView",
  components: {FooterComponent, NavbarMenu, ContactComponent}
}
</script>
