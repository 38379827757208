<template>
  <NavbarMenu/>
  <Carousel/>
  <AboutComponent/>
  <ServiceComponent/>
  <FreeSection/>
  <HowComponent/>
  <FooterComponent/>
</template>

<script>
import Carousel from "@/components/CarouselSlider.vue";
import NavbarMenu from "@/components/NavbarMenu.vue";
import AboutComponent from "@/components/AboutComponent.vue";
import ServiceComponent from "@/components/ServiceComponent.vue";
import FreeSection from "@/components/FreeSection.vue";
import HowComponent from "@/components/HowComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name:"HomeView",
  components:{
    FooterComponent,
    HowComponent,
    FreeSection,
    ServiceComponent,
    AboutComponent,
    NavbarMenu,
    Carousel
  }
}
</script>
