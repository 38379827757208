<template>
  <NavbarMenu/>
  <TitleComponent title="Nosotros"/>
  <AboutComponent></AboutComponent>
  <FooterComponent/>
</template>
<script>
import AboutComponent from "@/components/AboutComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import NavbarMenu from "@/components/NavbarMenu.vue";
import TitleComponent from "@/components/TitleComponent.vue";

export default {
  name: "AboutView",
  components: {TitleComponent, NavbarMenu, FooterComponent, AboutComponent}
}
</script>

<style scoped>
</style>
