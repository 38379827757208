<template>
  <div class="container-fluid py-1">
    <div class="container">
      <div class="section-title">
        <h4 class="text-primary text-uppercase" style="letter-spacing: 5px;">¿Quienes somos?</h4>
        <h1 class="display-4">Innovación Tecnológica</h1>
      </div>
      <div class="row">
        <div class="col-lg-4 py-0 py-lg-5">
          <h1 class="mb-3">Nuestra Historia</h1>
          <p>Desde su fundación en 2016, <strong>Digital Codex</strong> se ha embarcado en un viaje continuo hacia la
            excelencia en el desarrollo de software, guiado por una filosofía intrínseca de búsqueda de calidad e
            innovación constante. Con un enfoque insaciable en la creación de soluciones informáticas de calidad, cada
            línea de código es tejida con precisión, impulsada por una pasión por entregar productos de alto calibre.
            Más allá de la calidad, nuestra búsqueda inquebrantable de innovación nos impulsa a superar límites y
            explorar nuevas posibilidades, brindando soluciones más inteligentes, efectivas y eficientes.</p>
          <p>En <strong>Digital Codex</strong>, construimos puentes tecnológicos que conectan las necesidades del mundo
            moderno con soluciones ingeniosas, con el claro propósito de impulsar el futuro a través del software.
            Nuestra historia es un testimonio de logros, y nuestro futuro es un lienzo en blanco lleno de posibilidades.
          </p>
        </div>
        <div class="align-items-center col-lg-4" style="min-height: 500px; padding-top: 10%; z-index: 100">
          <div class="mb-3 ">
            <img class="mb-3 h-100 about " src="../assets/img/nosotros.png" alt="nosotros">
          </div>
        </div>
        <div class="col-lg-4 py-0 py-lg-5">
          <h1 class="mb-3">Nuestra Visión</h1>
          <p>En <strong>Digital Codex</strong>, nos esforzamos por ser líderes en la industria tecnológica, ofreciendo
            soluciones informáticas de alta calidad que impulsan el progreso y la eficiencia en todos los sectores.
            Nuestro propósito es claro: impulsar el futuro a través del software, construyendo puentes tecnológicos que
            conectan las necesidades del mundo moderno con soluciones ingeniosas.</p>
          <p>
            En nuestra visión, cada línea de código es una obra maestra y la innovación es la norma. Trascendemos los
            límites establecidos, abrazando el desafío de explorar nuevas posibilidades y ofreciendo soluciones más
            inteligentes, efectivas y eficientes.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "AboutComponent",
}
</script>

<style scoped>
.about {
  margin-left: -100px;
  object-fit: contain;
  width: 150%;
  opacity: 0.5;
  rotate: -35deg;
}

@media (max-width: 768px) {
  .about {
    margin-left: 0;
    object-fit: contain;
    width: 100%;
    opacity: 0.7
  }
}

</style>
