<template>
  <div class="container-fluid pt-5">
    <div class="container">
      <div class="section-title">
        <h4 class="text-primary text-uppercase" style="letter-spacing: 5px;">Nuestros Servicios</h4>
        <h1 class="display-4">Transformación Digital</h1>
      </div>
      <div class="row">
        <div class="col-lg-6 mb-5">
          <div class="row align-items-center">
            <div class="col-sm-5">
              <img class="img-fluid mb-3 mb-sm-0" width="200" height="200" src="../assets/img/solucionesamedida.jpeg" alt="desarrollo de software">
            </div>
            <div class="col-sm-7">
              <h4>Soluciones a Medida</h4>
              <p class="m-0">Creamos software personalizado para satisfacer las necesidades únicas de tu negocio. Desde el diseño hasta la implementación, nuestro servicio de Desarrollo de Software te ofrece soluciones innovadoras y eficientes para impulsar el éxito de tu empresa.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-5">
          <div class="row align-items-center">
            <div class="col-sm-5">
              <img class="img-fluid mb-3 mb-sm-0" width="200" height="200" src="../assets/img/revoluciondigital.jpeg" alt="desarrollo software">
            </div>
            <div class="col-sm-7">
              <h4>Revolución Digital</h4>
              <p class="m-0">Nuestro servicio te ofrece una transformación disruptiva impulsada por tecnologías digitales. Redefinimos cómo vives, trabajas y te relacionas, trayendo eficiencia y valor a tu vida y negocio.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-5">
          <div class="row align-items-center">
            <div class="col-sm-5">
              <img class="img-fluid mb-3 mb-sm-0" width="200" height="200" src="../assets/img/datasolution.jpeg" alt="data y analitica">
            </div>
            <div class="col-sm-7">
              <h4>Data Driven Solutions</h4>
              <p class="m-0">Nuestro servicio de Data & Analytics te brinda las herramientas y el conocimiento necesarios para aprovechar al máximo tus datos. Desde la recopilación hasta la interpretación, te ayudamos a tomar decisiones informadas y estratégicas que impulsen el crecimiento y la eficiencia de tu negocio.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-5">
          <div class="row align-items-center">
            <div class="col-sm-5">
              <img class="img-fluid mb-3 mb-sm-0" width="200" height="200" src="../assets/img/web.jpeg" alt="desarrollo web">
            </div>
            <div class="col-sm-7">
              <h4>Experiencias Digitales Personalizadas</h4>
              <p class="m-0">Nuestro servicio de Desarrollo Web se centra en brindarte una plataforma digital sólida y atractiva. Nos dedicamos a construir un sitio web que se adapte perfectamente a tus necesidades y objetivos.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 mb-5">
          <div class="row align-items-center">
            <div class="col-sm-5">
              <img class="img-fluid mb-3 mb-sm-0" width="200" height="200" src="../assets/img/impulso.jpeg" alt="marketinbg digital">
            </div>
            <div class="col-sm-7">
              <h4>Impulso Digital</h4>
              <p class="m-0">Nuestro servicio de Marketing Digital te ayuda a aumentar la visibilidad de tu marca en línea a través de estrategias efectivas de SEO, publicidad en redes sociales y marketing de contenido. Alcanza a tu audiencia objetivo y mejora tu retorno de inversión con nuestras soluciones personalizadas.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-5">
          <div class="row align-items-center">
            <div class="col-sm-5">
              <img class="img-fluid mb-3 mb-sm-0" width="200" height="200" src="../assets/img/nube.jpeg" alt="infraestructura de nube">
            </div>
            <div class="col-sm-7">
              <h4>Nube Inteligente</h4>
              <p class="m-0">Nuestro servicio de IT & Cloud ofrece soluciones innovadoras para optimizar la infraestructura tecnológica de tu empresa. Desde la migración a la nube hasta la gestión de datos y seguridad, te ayudamos a aprovechar al máximo las últimas tecnologías para impulsar la eficiencia y la escalabilidad de tu negocio.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 mb-5">
          <div class="row align-items-center">
            <div class="col-sm-5">
              <img class="img-fluid mb-3 mb-sm-0" width="200" height="200" src="../assets/img/hw.jpg" alt="hardware">
            </div>
            <div class="col-sm-7">
              <h4>Soluciones de Hardware</h4>
              <p class="m-0">Ofrecemos una amplia gama de productos y servicios de hardware para satisfacer tus necesidades tecnológicas. Desde equipos informáticos hasta dispositivos periféricos y accesorios, nuestro catálogo cuenta con las últimas innovaciones para impulsar la productividad y el rendimiento de tu empresa.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-5">
          <div class="row align-items-center">
            <div class="col-sm-5">
              <img class="img-fluid mb-3 mb-sm-0" width="200" height="200" src="../assets/img/infra.jpg" alt="infraestructura">
            </div>
            <div class="col-sm-7">
              <h4>Infraestructura Conectada</h4>
              <p class="m-0">Nuestro servicio de Redes de Datos y Cableado Estructurado proporciona soluciones robustas y confiables para garantizar una conectividad eficiente en tu empresa. Nos aseguramos de que tu empresa esté equipada con una base tecnológica sólida que soporte el flujo de información y la comunicación sin problemas.</p>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceComponent",
}
</script>

<style scoped>
</style>
